import { Show, useShowController } from "react-admin";
import { useLocation } from "react-router-dom";

import mixpanel from "mixpanel-browser";

import OrgHeader from "./OrgHeader";
import OrgTabs from "./OrgTabs";

const OrgShow = () => {
  mixpanel.track("OrgShow");
  const location = useLocation();

  const orgId = extractOrgIdFromPath(location.pathname);
  const controllerProps = useShowController({ resource: "Org", id: orgId });
  return (
    <Show
      title="Org Details"
      {...controllerProps}
      emptyWhileLoading
      actions={false}
      component="div"
    >
      <OrgHeader />
      <OrgTabs />
    </Show>
  );
};

// Extract org ID from URL: `/Org/org-f2d9967c4cab422a8ee2769f9de22fbd,org/show`
const extractOrgIdFromPath = (path: string) => {
  // Try matching the legacy format first: /Org/org-<id>,org/show/1
  const legacyRegex = /\/([^/]+,org)\/show$/;
  const legacyMatch = path.match(legacyRegex);
  if (legacyMatch) {
    return legacyMatch[1];
  }

  // Try matching the new format: /Org/<id>/show/1
  const newRegex = /\/Org\/([^/]+)\/show/;
  const newMatch = path.match(newRegex);
  if (newMatch) {
    return newMatch[1];
  }

  console.log("Could not extract org ID from path: ", path);
  return null;
};

export default OrgShow;
