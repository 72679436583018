import { forwardRef } from "react";
import { Link, LinkProps } from "react-admin";
import { Button } from "@mui/material";
import { runeTheme } from "../../common/RuneTheme";

export interface TertiaryActionButtonProps {
  link: string;
  state: string | Record<string, unknown>;
  icon: string;
  label: string;
}

// Disabling warning as actually using ref in Link component triggers other console warning
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const ForwardedLink = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
  <Link {...props} to={props.to || ""} />
));

const TertiaryActionButton = (props: TertiaryActionButtonProps) => {
  return (
    <Button
      component={ForwardedLink}
      variant="text"
      to={{
        pathname: props.link
      }}
      state={props.state}
      sx={{
        display: "flex",
        padding: "16px",
        gap: "4px",
        height: "19px",
        fontSize: "16px",
        lineHeight: "19px",
        letterSpacing: "-0.03em",
        textTransform: "capitalize",
        fontWeight: "600",
        color: runeTheme.palette.button.tertiary.text,
        margin: "8px"
      }}
    >
      <img src={props.icon} alt="" />
      <span>{props.label}</span>
    </Button>
  );
};

export default TertiaryActionButton;
